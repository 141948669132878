<script>
export default {
  metaInfo: {
    title: 'HoneyMoney Reviews',
    meta: [
      { name: 'description', content: 'HoneyMoney Reviews' }
    ]
  }
}
</script>

<template lang="pug">
.testimonials_page
  section.hero.is-info(style="background-color: purple;")
    .hero-body
      .container.content.has-text-centered
        .title.is-1 People Love HoneyMoney
  section.section.testimonials
    .container.content
      .columns
        .column.is-10.is-offset-1
          .columns.is-vcentered
            .column.has-text-centered
              img(width='700', src='./assets/wordcloud.png')
            .column.is-narrow
              .box.content
                .subtitle.is-5 Reviews around the Internet:
                ul
                  li: a(target='_blank' href='http://www.budgetsaresexy.com/3-new-financial-apps-services-you-might-like/') BudgetsAreSexy.com
                  li: a(target='_blank' href='https://moneybuffalo.com/honeymoney-legit') MoneyBuffalo.com
                  li: a(target='_blank' href='http://yourmoneyyourfreedom.com/safeguard-personal-data-with-honeymoney-budgeting-app/') YourMoneyYourFreedom.com
                  li: a(target='_blank' href='http://agaishanlife.com/2018/05/review-honey-money/') AgaishanLife.com

          .testimonial-container.left.testimonial-green
            blockquote.testimonial
              p
                | I like HoneyMoney a lot. Its design and functionality are amazing. I always wanted to start personal budget but used to put it off because it is such a routine and boring process. HoneyMoney is literally a life savior.
                strong  It is interesting and useful.
                |  Thank you so much for this website!
            .testimonial-arrow-down
            p.testimonial-author
              | Gleb
              span  (2 years with HoneyMoney)

          .testimonial-container.right.testimonial-orange
            blockquote.testimonial
              p HoneyMoney helps me so much! Especially, if you take into account that my income is not regular and hardly predictable. In my situation, it’s difficult to calculate how much money I can spend, but with HoneyMoney there is no problem at all. The software shows me how much should be reserved for my planned expenses and goals, and I can safely spend the rest.
              p Thank you, HoneyMoney is very handy.
            .testimonial-arrow-down
            p.testimonial-author
              | Natalya
              span  (using HoneyMoney for 4 years)

          .testimonial-container.left.testimonial-blue
            blockquote.testimonial
              p It’s been more than a year since my spouse and I started using HoneyMoney. Before that we tried a dozen different systems for family bookkeeping, but none of those actually worked for us. HoneyMoney is as simple as a regular notebook to record daily expenses, but it also has functions for long term budget planning. Its friendly interface is as simple as it could be. HoneyMoney is a convenient tool, which helps you stop worrying and it turns exhausting family bookkeeping into a joyful process.
            .testimonial-arrow-down
            p.testimonial-author
              | Vladimir
              span  (1 year with HoneyMoney)
          .testimonial-container.right.testimonial-pink
            blockquote.testimonial
              p
                | HoneyMoney takes a weight off your mind:
                br
                | You need to sit down once, think and record the income and expenses you are planning to make, and that’s it, you don’t have to worry about your family budget anymore! Just track your actual expenses and make sure they don’t exceed the expenses you entered in the system, and your income isn’t less (it’d be better if it’s higher) than planned.
              p The second awesome feature is setting goals for savings. If you have a financial goal to purchase something (new phone, TV, a trip to Tahiti, etc.) in the nearest future, the system will start to save for it little by little. When the due date arrives, you can spend money without any hesitation, and it doesn’t affect your family budget.
              p This feature helped us create our “financial cushion” – a reserve fund (although, we spent it when building our new house, and now we are saving for it again :) ), after filling up our reserve fund we planned little but very pleasant purchases: new car seat for our child, swing set for our garden, summer tires, 100 roses for mother-in-law for her 50th birthday.
            .testimonial-arrow-down
            p.testimonial-author
              | Yuri
              span  (5 years with HoneyMoney)
          .testimonial-container.right.testimonial-yellow
            blockquote.testimonial
              h2 What I like:
              ul
                li
                  strong  Friendly interface and lovely design.
                  |  The first thing I do is evaluating the interface. I’ve tried over a dozen systems for family budgeting, and I decided to go with HoneyMoney: everything is simple and it looks great.
                li
                  strong  It doesn’t have many unnecessary features.
                  |  Other systems may have more functions, but those are not very useful, on top of that it makes interface complicated.
                li
                  strong  Simple and comprehensive expense planning.
                  |  In other systems I had to click “add expense”, then select a date in a small calendar, and fill out a bunch of fields... Here I can just click on a date in the big calendar and type the amount with description in the same field. It’s only a few clicks less, but it’s super convenient.
                li
                  strong  Price.
                  |  From the very beginning, I decided that I didn’t want to pay more than $5 per month for the system.
                li
                  | And there are a lot of other small but pleasant details (interface colors, etc.) which create the general impression of the system.
              p All in all, I like that the developers spent a lot of time to design the interface. Thank you for such a wonderful system for family bookkeeping. It’s so nice to work with it :-)
            .testimonial-arrow-down
            p.testimonial-author Alexey
          .testimonial-container.left.testimonial-green
            blockquote.testimonial
              h2 This program is magnificent!
              ul
                li
                  strong  User-friendly interface
                  |  (one of the biggest advantages of HoneyMoney is its interface – it is awesome! Beautiful, simple and there are no annoying elements!)
                li <b>Convenient</b> expense/income tracking and display
                li Tracking and planning right inside the <b>calendar</b>
                li Tracking the total amount by categories/subcategories
                li
                  | An option to plan
                  strong  on the go
                  |  (there is no need to go to another section, where you don’t see your current numbers).
                li <b>Drag & drop transactions</b> within the calendar without opening them (it saves time entering and changing plans, which is a frequent action)
                li
                  | Envelopes are super helpful.
                  strong  There’s no need to calculate if I have enough money or if I don’t
                li  No need to create categories in the very beginning, I was caught by surprise: “Where do I create categories?”, it was an awesome change.
            .testimonial-arrow-down
            p.testimonial-author Evgeniy
          .testimonial-container.left.testimonial-orange
            blockquote.testimonial
              p HoneyMoney allows to get all the necessary and interesting details about my budget with minimal effort, and that minimal effort you make is rather pleasant because the program works very well.
              p Not only <strong>I know how I spend my money, but also I can see the general picture</strong> – what is the expense level I need, and what level is unacceptable.
              p I am looking for a new job right now, and it is important to me to understand what kind of salary I should ask for. Your system helps me figure out what kind of income would be right for me. And if it was necessary, I could easily explain to my future employer why I need that salary. Thank you!
            .testimonial-arrow-down
            p.testimonial-author Anna

          .more_testimonials
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='4')
                h3.review-content-name HoneyMoney is a wonderful program!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p It is simple and easy to work with. It provides options for analyzing family financial funds and answers various financial questions. There is an option to set goals for savings.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      ul
                        li It's always "under active development" :)
                        li It's not free
                  p.review-content-text I've been using HoneyMoney for almost three years. Before that, I used to record my personal financial information in excel and other systems, but I was constantly unsatisfied, and I had to look for something new. Almost everything is perfect with HoneyMoney, all our family needs are covered by it, and I no longer need to search for a better option.
              .testimonial-arrow-down
              p.testimonial-author
                | Vladimir Semenov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name The best software for family bookkeeping!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      ul
                        li Incredible customer service. Several improvements were made promptly at my request. I haven’t seen anything like that before!
                        li There is an option to use envelopes for things like charity, investment, education. Only HoneyMoney offers such a flexible system to work with different accounts.
                        li Convenient reports allow you to quickly learn everything you need for any time period.
                        li Beautiful interface!
                        li And a very small price for such a great pleasure!
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p Sometimes I don’t get prompt replies from the customer support, but I get it. All of this is a one-man project, that’s why it is OK! However, it would be great if I got a reply notifying me when I should expect to hear back from the customer support. I am sure that we’ll get this feature in the future.
                  p.review-content-text
                    | I am simply in love with HoneyMoney. I told all my friends about it, and I highly recommend it. Thank you for a convenient, high-quality, beautiful product that really helps me! It’s a wonderful tool that develops a habit to control expenses and incomes!
                    br
                    br
                    |  Thank you for HoneyMoney!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Sergey Yastrebov', src='https://graph.facebook.com/315919175443438/picture')
                | Sergey Yastrebov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name HoneyMoney review
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p
                        | HoneyMoney is a delightful software for family bookkeeping. You can record your expenses very quickly (unless you make it complicated yourself), and you can correctly distribute them. If you need, you can, for example, set limits on entertainment. I've been using it for 3 years. It really helps me save more money.
                  p.review-content-text The system is very intuitive and constantly improving. Ildar who is the developer of this software is the true soul of this project.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Yulia Chigintseva', src='https://graph.facebook.com/1887494464818674/picture')
                | Yulia Chigintseva
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Good app
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p
                        | Good quality project, fast reports based on categories for the selected period, a lot of visualization.
                  p.review-content-text
                    | I’ve been using it for over a year now. Of course, even Excel works well for me, but visualization is very convenient, and this service is not very expensive.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Alexandr Kornienko', src='https://graph.facebook.com/1845178822384224/picture')
                | Alexandr Kornienko
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name It’s an excellent project with a customer support and an active development
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p Reporting system, very informative, customer support, simple to use, and user-friendly interface
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p I don’t see any serious disadvantages
                  p.review-content-text
                    | I started using HoneyMoney two and a half years ago. I switched from another application, which was abandoned and had lots of bugs. HoneyMoney doesn’t have this kind of issues. New features are added all the time. I receive detailed replies from customer support. It is easy to record information. It has comprehensive and flexible system to categorize expenses. There are excellent category-based reports, which have been updated recently, and now it is even more convenient to use them. In general, I think that HoneyMoney is very practical, informative and easy to use.
              .testimonial-arrow-down
              p.testimonial-author
                | Pavel Zakharov
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name An excellent software
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p
                        | I’d been looking for a simple and visual app for family bookkeeping for a long time.
                        br
                        | In this case, the system is quite flexible, but convenient at the same time. Now I can get statistics and set budget well.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p It’s missing some analytics.
                  p.review-content-text
                    | I was very surprised when I started saving a significant amount of money each month. I see how much money I have available and how much I need to save.
                    br
                    br
                    | Well done, guys, keep up the good work.
              .testimonial-arrow-down
              p.testimonial-author
                | Oleg Kleschuk
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name Review from an ordinary user
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      ul
                        li The main advantage for me is how simple it is to enter transactions. It is convenient to make records in the calendar on the day when the expense actually occurred. It is easy to apply categories and to create new ones. If I need to know how much I spent on a specific purchase or category I can easily find the amount and the date.
                        li There is expense and income analytics.
                        li It’s a very simple system to work with accounts (cash, cards, savings, etc.). All of them need to be accounted and not lost. At the same time, I can decide which accounts should be used in the budget (the amount is visible), and which ones shouldn’t.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p I’d like to have an option to record expenses without going to the website (something like a Google extension, for example).
                  p.review-content-text I’ve been using this program for 2 years. Before that, I tried to track my expenses in several free programs. However, HoneyMoney is the best.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Irina Tishkina', src='https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50')
                | Irina Tishkina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name HoneyMoney is an excellent program!
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      ul
                        li I love this program! One of the biggest advantages is the option to access it from any device + mobile web-app. It allows several users to record information whenever they want to and even at the same time! It is a wonderful option for the family budgeting.
                        li It’s very easy to record accounts and transactions. It took some time though to figure out how to plan reserves. It seemed very unusual, but it’s convenient when you get used to it.
                        li My husband and I have been using it for 8 months, and we are very pleased.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p Quite complicated envelopes system
                  p.review-content-text
                    | In general, it is a wonderful program. And the price is more than justified. I will continue to use it with great pleasure!
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Maria Sukonkina', src='https://graph.facebook.com/1110312985672538/picture')
                | Maria Sukonkina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name I have been using this program since 2014
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      p Everything is great! Now I know how much money I have, how much I spent, I can check statistics to see where I could have saved some money, I could have saved a lot of money. Yes, and now I’m not worried anymore, my finances are accounted. I like this software, it is simple and understandable, although it takes some time to get used to it. I use it every day.
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      p When all monthly transactions are entered in the app, it starts working slower, perhaps, because it becomes heavy. Sometimes it has a lag for one or two seconds, but after reloading the page, it seems to work well again.
                  p.review-content-text Great impression overall. I like HoneyMoney.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Yevsey Yaroshenko', src='https://lh3.googleusercontent.com/-cQ1HocicTmk/AAAAAAAAAAI/AAAAAAAADyA/e3HzpRan7U4/photo.jpg?sz=50')
                | Yevsey Yaroshenko
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name HoneyMoney is a nice application to track personal finances
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      ol
                        li Beautiful and pleasant interface
                        li Mobile website version
                        li Fast and high-quality customer support
                        li Easy to use
                        li Outstanding documentation
                        li Complete anonymity and safety
                        li Constant project development, including customers’ requests and comments
                  .review-content-minuses.columns
                    .column.is-2(style='align-self: center')
                      h4 Disadvantages:
                    .column
                      ol
                        li No custom account categories
                  p.review-content-text
                    | I started using the system at the end of 2013 when my friend recommended it to me. I am very satisfied. The app does its job perfectly well! Perhaps, I am conservative, but I didn’t even try to look for something better. The system satisfies 90% of my needs, although I use only a few functions.
                    br
                    br
                    |     HoneyMoney was built for people; it makes a great impression on each user, everything is very simple.
                    br
                    br
                    |     To all of those who still don’t track their cash flow, I sincerely recommend this product.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Inna Kalinkina', src='https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg?sz=50')
                | Inna Kalinkina
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                .review-content-rating(content='5')
                h3.review-content-name This is an app for those who like to keep their finances under control
                .review-content-body
                  .columns.review-content-pluses
                    .column.is-2(style='align-self: center')
                      h4 Advantages:
                    .column
                      ul
                        li simple and convenient
                        li easy to plan income and expenses
                        li envelope-budgeting
                        li statistics
                        li mobile website
                        li acceptable price
                        li no ads
                        li continuous service development
                  p.review-content-text
                    |  I’ve been using HoneyMoney for over 2 years. I liked it right from the start because of its simplicity and convenience. It is easy to record income and expenses, and categories are created automatically. There are a lot of useful statistics and charts about income/expenses, and net amounts for different periods. Managing debts and different currencies is very simple.
                    br
                    br
                    |  Envelopes allow to easily plan expenses, including category-based expenses. At the same time, HoneyMoney automatically calculates the amount of available funds for unplanned expenses, including future expenses. In tough times it helps to prevent the situation when there is not enough money for critical expenses.
                    br
                    br
                    |  The program creator is very responsive to users (for example, he quickly took into account the currency in Belarus). And in general, the service is constantly developing.
              .testimonial-arrow-down
              p.testimonial-author
                img(alt='Evgeniy Myslovets', src='https://lh4.googleusercontent.com/-vqPeDAAiABY/AAAAAAAAAAI/AAAAAAAAAPM/r4MMcFVj2KU/photo.jpg?sz=50')
                | Evgeniy Myslovets

  section.hero.is-light.is-bold: .hero-body: .container.content.has-text-centered
    .title.is-2 "I love it!", Ildar (10+ years of using HoneyMoney)
    .subtitle.is-4.has-text-centered It can work for you too
    a.button.is-info.is-rounded(:href="HM.demo_url") 👀 See How It Works in Demo
    span.is-size-4 &nbsp;&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;&nbsp;
    a.button.is-success.is-rounded(:href="HM.app_url + '/app/sign_up'") ✔ Try HoneyMoney
    .has-text-right: router-link.button.is-text(to="/en/compare") Compare with Other Tools »
</template>
